const appendElementToContainer = (container: string, elementId: string): void => {
  const containerElement = document.getElementById(container);
  const microFrontendElement = document.getElementById(elementId);

  if (containerElement && !microFrontendElement) {
    const div = document.createElement('div');
    div.setAttribute('id', elementId);
    containerElement.appendChild(div);
  }
};

export { appendElementToContainer };
