import { appendElementToContainer } from './appendElementToContainer';
import { getConfig } from './getConfig';
import { MicroFrontend } from './interfaces/Microfrontend';
import { PageStructure } from './interfaces/PageStructure';
import { regexMatchesUrl } from './regexMatchesUrl';
import { registerMicroFrontend } from './registerMicrofrontend';

const initializePage = async (page: PageStructure): Promise<void> => {
  (page.microfrontends as MicroFrontend[]).map(async (microFrontend: MicroFrontend) => {
    appendElementToContainer(microFrontend.container, microFrontend.elementId);
    await registerMicroFrontend(microFrontend.name, microFrontend.bundle, page.route);
  });
};

const initializeMicroFrontendsBasedOnUrl = async (): Promise<void> => {
  const currentPage = getConfig().find((page: PageStructure) => {
    return regexMatchesUrl(page.route, window.location);
  });

  if (currentPage) {
    await initializePage(currentPage);
  }
};

export const initializeMicroFrontends = async (): Promise<void> => {
  await initializeMicroFrontendsBasedOnUrl();
  window.addEventListener('popstate', initializeMicroFrontendsBasedOnUrl);
};
