/* eslint-disable @typescript-eslint/no-explicit-any */
import { registerApplication, start } from 'single-spa';
import { GlobalEventDistributor } from './globalEventDistributor';
import 'babel-polyfill';
import { initializeMicroFrontends } from './microfrontends';

declare global {
  interface Window {
    System: any;
    dataLayer: any[];
  }
  interface StoreModule {
    storeInstance?: any;
  }

  type Maybe<T> = T | null;
}
interface CustomProps {
  store?: any;
  globalEventDistributor?: GlobalEventDistributor;
  domElementGetter?: Function;
}

const onRootMounted = (): void => {
  window.removeEventListener('greencare:ready', onRootMounted);
  initializeMicroFrontends();
};

window.addEventListener('greencare:ready', onRootMounted);

registerApplication({
  name: 'root',
  activeWhen: () => true,
  app: () => import('./index.singlespa'),
});

start();
