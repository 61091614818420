export const CONSULTANT_GROUPS_MANAGEMENT = 'greencare_consultant_groups_management';
export const CUSTOMER_REGISTRATION = 'greencare_customer_registration';
export const SEARCH = 'greencare_search';
export const CLIENT_PROFILE = 'greencare_client_profile';
export const TASK_PLATFORM = 'greencare_task_platform';
export const LOAN_RESTRUCTURING_PANEL = 'greencare_loan_restructuring_panel';
export const LOAN_RESTRUCTURING = 'greencare_loan_restructuring';
export const CUSTOMER_SERVICE = 'greencare_customer_service';
export const NEGOTIATION = 'greencare_negotiation';
export const FUNDING_BLOCK_LIST = 'greencare_funding_block_list';
export const FUNDING_ASSIGNMENTS = 'greencare_funding_assignments';
export const FUNDING_RESTRUCTURING = 'greencare_funding_restructuring';
export const FUNDING_REGISTRATION = 'greencare_funding_registration';
