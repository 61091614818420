import { pathToRegexp } from 'path-to-regexp';

const getCurrentUrl = (location: Location): string => location.pathname;

const regexMatchesUrl = (route: string, location: Location): boolean => {
  const routeRegex = pathToRegexp(route, [], { end: false });

  return routeRegex.test(getCurrentUrl(location));
};

export { regexMatchesUrl };
