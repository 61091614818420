import { registerApplication, getAppStatus, pathToActiveWhen } from 'single-spa';

const loadBundle = (url: string): Promise<any> => window.System.import(url);

const isMicroFrontendAlreadyRegistered = (microFrontendName: string): boolean =>
  !!getAppStatus(microFrontendName);

const registerMicroFrontend = async (name: string, bundle: string, route: string): Promise<any> => {
  if (isMicroFrontendAlreadyRegistered(name)) {
    return;
  }

  registerApplication({
    name,
    activeWhen: pathToActiveWhen(route),
    app: await loadBundle(bundle),
  });
};

export { registerMicroFrontend };
